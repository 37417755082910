<div fxFlex fxLayout ="row" fxLayoutAlign=" center" class="nav-sub">
    <button mat-icon-button color="primary" class="menu-button" [matMenuTriggerFor]="menu">
        <mat-icon class="cm-menu"></mat-icon>
    </button>
    <button mat-icon-button color="primary" class="search-button" (click)="showSearch()">
        <mat-icon class="cm-search"></mat-icon>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" >
        <button mat-menu-item routerLink="/overview" routerLinkActive="active">{{'header.about' | translate}}</button>
        <button mat-menu-item routerLink="/topography" routerLinkActive="active">{{'header.topography' | translate}}</button>
        <button mat-menu-item routerLink="/topology" routerLinkActive="active">{{'header.topology' | translate}}</button>
        <button mat-menu-item routerLink="/book" routerLinkActive="active">{{'header.book' | translate}}</button>
        <button mat-menu-item routerLink="/team" routerLinkActive="active">{{'header.team' | translate}}</button>
        <button mat-menu-item routerLink="/disclosure" routerLinkActive="active">{{'header.disclosures' | translate}}</button>
        <button mat-menu-item (click)="showSearch()" class="search-menu"><mat-icon class="cm-search search-icon-menu"></mat-icon>{{'header.search' | translate}}</button>
    </mat-menu>
</div>
<div class="logo-container" fxFlex="grow" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center">
    <img routerLink="/" [queryParams]="{info:'short'}" queryParamsHandling="merge" class="logo" src="./assets/logo.svg">
    <img routerLink="/" [queryParams]="{info:'short'}" queryParamsHandling="merge" class="logo-mobile" src="./assets/logo-small.svg">
</div>
<div fxFlex fxLayout="row" fxLayoutAlign="end" class="lang-selector">
    <span class="nav-lang">
        <a (click)="changeLanguage('en')" [ngClass]="{'active': lang === 'en'}">EN</a>
        <a (click)="changeLanguage('de')" [ngClass]="{'active': lang === 'de'}">DE</a>
    </span>
</div>
